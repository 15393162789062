import { React, useState, props } from 'react';

import { Modal, Button, Image, Card, Col, Row } from 'react-bootstrap';

import Snowfall from 'react-snowfall';

import snowLogo from '../../assets/summerWinterLogo.png';
import logoBird from '../../assets/LOGOshort.png';
import { Link } from 'react-router-dom';

import '../Animations/Snow/Snow.js';

const WinterSummerModal = (props) => {
	const [show, setShow] = useState(true);
	const values = [true];
	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);

	let url = 'color-your-voice.ticketleap.com/winter23/';

	return (
		<>
			{' '}
			<Modal
				{...props}
				size='md'
				aria-labelledby='contained-modal-title-vcenter'
				centered
				show={show}
				onHide={handleClose}
				className='background-winter-summer'>
				<Snowfall />
				<Modal.Header closeButton className='border-0'></Modal.Header>
				<Modal.Body className='p-4 text-center '>
					<Image
						width='80%'
						className='img-responsive mx-auto'
						src={snowLogo}
						fluid
					/>
					<Card className='display-6 border-0 text-center mt-0 p-2'>
						<strong>Winter Recital 2025</strong>{' '}
						<div className='fs-5 mt-0 text-center'>
							- two shows -
						</div>
						<div className='fs-4 mt-2 text-center'>
							Sunday, Jan 12<sup>th</sup>{' '}
						</div>
					</Card>
					<Row className=''>
						<Col xs={6} className='p-1'>
							<Button
								variant='secondary'
								size='lg'
								className='mt-3 fw-light'
								onClick={handleClose}
								href='https://www.ticketleap.events/tickets/color-your-voice/color-your-voice-winter-recital-show-1-259849783'
								target='_blank'>
								1:00PM
							</Button>
						</Col>
						<Col xs={6} className='p-1 '>
							<Button
								variant='warning'
								size='lg'
								className='mt-3 fw-light'
								onClick={handleClose}
								href='https://www.ticketleap.events/tickets/color-your-voice/color-your-voice-winter-recital-show-2'
								target='_blank'>
								4:00PM
							</Button>
						</Col>
					</Row>
					<div className='fs-5 mt-0 text-center'>- buy tickets -</div>
				</Modal.Body>

				{/* <Modal.Footer className='border-0 bg-transparent p-3 mx-auto'>
					<div className='fs-5 '>New to</div>
					<Image
						width='55px'
						height='auto'
						className='p-0 me-0'
						src={logoBird}
						id='logo'
					/>
					<div className='fs-5 me-3'>?</div>{' '}
					<div className='fs-5 '>
						{' '}
						<Button
							variant=''
							size='lg'
							className='text-orange'
							onClick={handleClose}
							href='/#waitlist'>
							Click Here!
						</Button>
					</div>
				</Modal.Footer> */}
			</Modal>
			;{' '}
		</>
	);
};

export default WinterSummerModal;
